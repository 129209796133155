import { Component, OnInit, ElementRef, ViewChild, HostListener, Renderer2 } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { Contact, MailRequest } from 'src/app/model';
import { EmailServiceService } from 'src/app/email-service.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
@Component({
  selector: 'app-home-arabic',
  templateUrl: './home-arabic.component.html',
  styleUrls: ['./home-arabic.component.scss'],
  animations: [
    trigger('contentAnimation', [
      transition(':enter', [
        style({
          transform: 'translateX(100%)', opacity: 0
        }),
        animate('0.3s ease-in', style({
          transform: 'translateX(0)', opacity: 1
        }))
      ]),
      transition(':leave', [
        animate('0.3s ease-out', style({
          transform: 'translateX(100%)', opacity: 0
        }))
      ])
    ]),
    trigger('partnerItemAnimation', [
      transition(':enter', [
        style({
          transform: 'translateX(100%)', opacity: 1
        }),
        animate('0.4s ease-out', style({
          transform: 'translateX(0px)', opacity: 1
        }))
      ]),
      transition(':leave', [
        style({
          transform: 'translateX(0px)', opacity: 1
        }),
        animate('0.4s ease-in', style({
          transform: 'translateX(-100%)', opacity: 1
        }))
      ])
    ]),
    trigger('feedbackSuccessAnimation', [
      transition(':enter', [
        style({
          height: 0, opacity: 0, overflow: 'hidden'
        }),
        animate('0.4s ease-out', style({
          height: '*', opacity: 1, overflow: 'hidden'
        }))
      ])
    ]),
    trigger('OverlayAnimation', [
      transition(':enter', [
        style({
          opacity: 0,
          'pointer-events': 'none'
        }),
        animate('0.3s ease-in', style({
          opacity: 1,
          'pointer-events': 'all'
        }))
      ]),
      transition(':leave', [
        animate('0.3s ease-out', style({
          opacity: 0,
          'pointer-events': 'none'
        }))
      ])
    ])
  ]
})
export class HomeArabicComponent implements OnInit {

  /////////PUBLIC VARIABLES////////////
  @ViewChild('coreValuesSliderParent', { static: false }) coreValuesSliderParent: ElementRef;
  @ViewChild('partnerSliderParent', { static: false }) partnerSliderParent: ElementRef;
  @ViewChild('mainSliderParent', { static: false }) mainSliderParent: ElementRef;
  @ViewChild('teamSliderParent', { static: false }) teamSliderParent: ElementRef;
  @ViewChild('header', { static: false }) header: ElementRef;
  public scrollTopButton: boolean = false;
  public mobileMenu: boolean = false;
  public AMLPolicy: boolean = false;
  public TERMSPolicy: boolean = false;
  public ConsumerEducation: boolean = false;
  public FraudPrevention: boolean = false;
  public InfoConsumer: boolean = false;
  // update only mainSliderList for changes in main slider.
  // update only mainSliderList for changes in main slider.
  public mainSliderList = [
    {
      img: '../../../assets/images/banner4.jpg',
      imgWebp: '../../../assets/images/webp/banner4.webp',
      heading: '',
      subheading: '',
      paragraph: '',
      subparagraph: ''
    },
    {
      img: '../../../assets/images/banner27022022-1.jpg',
      imgWebp: '../../../assets/images/webp/banner27022022-1.webp',
      heading: '',
      subheading: '',
      paragraph: '',
      subparagraph: ''
    },
    {
      img: '../../../assets/images/banner27022022-2.jpg',
      imgWebp: '../../../assets/images/webp/banner27022022-2.webp',
      heading: '',
      subheading: '',
      paragraph: '',
      subparagraph: ''
    },
    {
      img: '../../../assets/images/banner27022022-3.jpg',
      imgWebp: '../../../assets/images/webp/banner27022022-3.webp',
      heading: '',
      subheading: '',
      paragraph: '',
      subparagraph: ''
    },
    {
      img: '../../../assets/images/banner27022022-4.jpg',
      imgWebp: '../../../assets/images/webp/banner27022022-4.webp',
      heading: '',
      subheading: '',
      paragraph: '',
      subparagraph: ''
    },
    {
      img: '../../../assets/images/banner27022022-5.jpg',
      imgWebp: '../../../assets/images/webp/banner27022022-5.webp',
      heading: '',
      subheading: '',
      paragraph: '',
      subparagraph: ''
    },
    
    // {
    //   img: '../../../assets/images/banner1.jpg',
    //   imgWebp: '../../../assets/images/webp/banner1.webp',
    //   heading: 'Foreign',
    //   subheading: 'Exchange',
    //   paragraph: 'Buy & Sell All major',
    //   subparagraph: 'currencies of the world'
    // },
  ];
  // do noupdate mainSliderLoopingList for changes in main slider.
  public mainSliderLoopingList = [];
  // update only partnerSliderList for changes in partner slider.
  public partnerSliderList = [
    {
      img: '../../../assets/images/Our-Banking-Partners/axis.png',
      imgWebp: '../../../assets/images/Our-Banking-Partners/webp/axis.webp'
    },
    {
      img: '../../../assets/images/Our-Banking-Partners/icici.png',
      imgWebp: '../../../assets/images/Our-Banking-Partners/webp/icici.webp'
    },
    {
      img: '../../../assets/images/Our-Banking-Partners/landbank.png',
      imgWebp: '../../../assets/images/Our-Banking-Partners/webp/landbank.webp'
    },
    {
      img: '../../../assets/images/Our-Banking-Partners/transfast.png',
      imgWebp: '../../../assets/images/Our-Banking-Partners/webp/transfast.webp'
    },
    {
      img: '../../../assets/images/Our-Banking-Partners/uremit.png',
      imgWebp: '../../../assets/images/Our-Banking-Partners/webp/uremit.webp'
    },
    {
      img: '../../../assets/images/Our-Banking-Partners/bdo.png',
      imgWebp: '../../../assets/images/Our-Banking-Partners/webp/bdo.webp'
    },
    {
      img: '../../../assets/images/Our-Banking-Partners/indusind.png',
      imgWebp: '../../../assets/images/Our-Banking-Partners/webp/indusind.webp'
    },
    {
      img: '../../../assets/images/Our-Banking-Partners/AUB.png',
      imgWebp: '../../../assets/images/Our-Banking-Partners/webp/AUB.webp'
    },
    {
      img: '../../../assets/images/Our-Banking-Partners/BPI.jpg',
      imgWebp: '../../../assets/images/Our-Banking-Partners/webp/BPI.webp'
    },
  ];
  // do noupdate partnerSliderRotatingList for changes in partner slider.
  public teamSliderNextDisabled: boolean = false;
  public mainSliderPrevDisabled: boolean = false;
  public mainSliderNextDisabled: boolean = false;
  public coreSliderNextDisabled: boolean = false;
  public coreSliderPrevDisabled: boolean = true;
  public teamSliderPrevDisabled: boolean = true;
  public partnerSliderItemFlag: boolean = true;
  public feedbackSuccessFlag: boolean = false;
  public blockAutoMainSlider: boolean = false;
  public partnerSliderRotatingList = [];
  public formInvalid: boolean = true;
  public emailRequest: MailRequest;
  // make team hide true to make out team visible
  public teamHide: boolean = false;
  public feedback = new Contact();
  public feedbackForm: FormGroup;
  public EmailContent: string;

  /////////PRIVATE VARIABLES////////////
  private _mainSliderLoopFlagRight: boolean = false;
  private _mainSliderLoopFlag: boolean = false;
  private _coreValuesSliderIndex = 0;
  private _autoSliderDelay = 5200;
  private _sliderItemsShowing = 4;
  private _partnerSliderIndex = 0;
  private _emailSub: Subscription;
  private feedbackModel: Contact;
  private _mainSliderIndex = 1;
  private _teamSliderIndex = 0;
  //update submit email path to recieve emails to the given email address.
  private _submitEmailId: string = 'customercare@jaziraexchange.com';
  // private _submitEmailId: string = 'ragesh@benzyinfotech.com';
  // update image path for customer feedback mail response.
  private _imagePath = 'https://www.jaziraexchange.ae/assets/images';

  copywrightDate=new Date().getFullYear();

  constructor(private router: Router, private renderer2: Renderer2, fb: FormBuilder, private mailService: EmailServiceService) {
    this.feedbackForm = fb.group({
      type: ['', Validators.required],
      branchVisited: ['', Validators.required],
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      feedback: ['', Validators.required]
    })
  }

  ngOnInit() {
    this.renderer2.addClass(document.body, 'arabic');
    this.initalizeMainSliderList();
    this.initializePartnerSliderList();
    this.setSliderItemCount();
    setInterval(() => {
      if (!this.blockAutoMainSlider) {
        this.mainSliderauto();
      }
      this.rotatePartnerSlider();
    }, this._autoSliderDelay);
  }

  ngOnDestroy() {
    this.renderer2.removeClass(document.body, 'overflow-hidden');
    this.renderer2.removeClass(document.body, 'arabic');
  }

  public showMobileMenu() {
    this.mobileMenu = true;
  }

  public hideMobileMenu() {
    this.mobileMenu = false;
  }

  public showAMLPolicy() {
    this.AMLPolicy = true;
    this.renderer2.addClass(document.body, 'overflow-hidden');
  }

  public hideAMLPolicy() {
    this.AMLPolicy = false;
    this.renderer2.removeClass(document.body, 'overflow-hidden');
  }

  public showTERMSPolicy() {
    this.TERMSPolicy = true;
    this.renderer2.addClass(document.body, 'overflow-hidden');
  }
  public hideTERMSPolicy() {
    this.TERMSPolicy = false;
    this.renderer2.removeClass(document.body, 'overflow-hidden');
  }
  public showConsumerEducation() {
    this.ConsumerEducation = true;
    this.renderer2.addClass(document.body, 'overflow-hidden');
  }
  public hideConsumerEducation() {
    this.ConsumerEducation = false;
    this.renderer2.removeClass(document.body, 'overflow-hidden');
  }

  public showFraudPrevention() {
    this.FraudPrevention = true;
    this.renderer2.addClass(document.body, 'overflow-hidden');
  }
  public hideFraudPrevention(){
    this.FraudPrevention = false;
    this.renderer2.removeClass(document.body, 'overflow-hidden');
  }
  public showInfoConsumer() {
    this.InfoConsumer = true;
    this.renderer2.addClass(document.body, 'overflow-hidden');
  }
  public hideInfoConsumer(){
    this.InfoConsumer = false;
    this.renderer2.removeClass(document.body, 'overflow-hidden');
  }
 

  

  @HostListener('window:scroll', [])
  pageScroll() {
    try {
      if (window.pageYOffset > 160) {
        this.scrollTopButton = true;
        this.renderer2.addClass(this.header.nativeElement, 'floating');
      } else {
        this.scrollTopButton = false;
        this.renderer2.removeClass(this.header.nativeElement, 'floating');
      }
    } catch (error) {
      console.error('Page scroll error : ', error);
    }
  }

  @HostListener('window:resize', [])
  setSliderItemCount() {
    try {
      if (window.innerWidth > 1000) {
        this._sliderItemsShowing = 4;
      } else if (window.innerWidth <= 1000 && window.innerWidth > 767) {
        this._sliderItemsShowing = 3;
      } else if (window.innerWidth <= 767 && window.innerWidth > 480) {
        this._sliderItemsShowing = 2;
      } else if (window.innerWidth <= 480) {
        this._sliderItemsShowing = 1;
      }
      this.resetValueSlider();
      this.resetTeamSlider();
    } catch (error) {
      console.error('Page resize error : ', error);
    }
  }

  // scroll section to view
  public scrollSection(element) {
    const offset = element.offsetTop - 74;
    this.scrollTo(offset);
  }

  // scroll window to y-offset position
  public scrollTo(offset) {
    this.hideMobileMenu();
    window.scrollTo({
      top: offset,
      behavior: 'smooth'
    });
  }

  // main slider begins
  initalizeMainSliderList() {
    const sliderLength = this.mainSliderList.length;
    this.mainSliderLoopingList.push(this.mainSliderList[sliderLength - 1]);
    this.mainSliderList.forEach((item) => {
      this.mainSliderLoopingList.push(item);
    });
  }
  public mainPrev() {
    this.addMainSliderAnimation();
    const sliderLength = this.mainSliderLoopingList.length;
    if (this._mainSliderIndex >= 0) {
      // index canot be -ve
      if (this._mainSliderIndex !== 0) {
        // going back before looping
        this._mainSliderLoopFlag = false;
        this._mainSliderLoopFlagRight = false;
        this._mainSliderIndex -= 1;
        const offset = 100 * this._mainSliderIndex;
        this.slideMainItem(offset);
      }
      if (this._mainSliderIndex == 0 && !this._mainSliderLoopFlag) {
        // going back and looping
        setTimeout(() => {
          this._mainSliderLoopFlagRight = true;
          this._mainSliderIndex = sliderLength - 1;
          // remove animation
          this.removeMainSliderAnimation();
          const offset = 100 * this._mainSliderIndex;
          this.slideMainItem(offset);
        }, 300);
      } else if (this._mainSliderIndex == 0 && this._mainSliderLoopFlag) {
        // going back after looping happens
        this._mainSliderLoopFlag = false;
        this._mainSliderLoopFlagRight = false;
        this._mainSliderIndex = sliderLength - 1;
        // remove animation
        this.removeMainSliderAnimation();
        const offset = 100 * this._mainSliderIndex;
        this.slideMainItem(offset);
        setTimeout(() => {
          this.addMainSliderAnimation();
          this._mainSliderIndex = sliderLength - 2;
          const offset1 = 100 * this._mainSliderIndex;
          this.slideMainItem(offset1);
        }, 100);
      }
    }
  }
  public mainNext() {
    this.addMainSliderAnimation();
    const sliderLength = this.mainSliderLoopingList.length;
    if (this._mainSliderIndex <= (sliderLength - 1)) {
      if (this._mainSliderIndex < (sliderLength - 1)) {
        this._mainSliderIndex += 1;
      }
      const offset = 100 * this._mainSliderIndex;
      this.slideMainItem(offset);
      if (this._mainSliderIndex == (sliderLength - 1) && !this._mainSliderLoopFlagRight) {
        setTimeout(() => {
          this._mainSliderLoopFlag = true;
          this._mainSliderIndex = 0;
          this.removeMainSliderAnimation();
          const offset = 100 * this._mainSliderIndex;
          this.slideMainItem(offset);
        }, 300);
      } else if (this._mainSliderIndex == (sliderLength - 1) && this._mainSliderLoopFlagRight) {
        this._mainSliderLoopFlagRight = false;
        this._mainSliderLoopFlag = false;
        this._mainSliderIndex = 0;
        this.removeMainSliderAnimation();
        const offset = 100 * this._mainSliderIndex;
        this.slideMainItem(offset);
        setTimeout(() => {
          this.addMainSliderAnimation();
          this._mainSliderIndex = 1;
          const offset1 = 100 * this._mainSliderIndex;
          this.slideMainItem(offset1);
        }, 100);
      }
    }
  }
  private addMainSliderAnimation() {
    for (let child of this.mainSliderParent.nativeElement.children) {
      this.renderer2.addClass(child, 'animation');
    }
  }
  private removeMainSliderAnimation() {
    for (let child of this.mainSliderParent.nativeElement.children) {
      this.renderer2.removeClass(child, 'animation');
    }
  }
  private slideMainItem(offset) {
    for (let child of this.mainSliderParent.nativeElement.children) {
      this.renderer2.setStyle(child, 'transform', `translate3d(-${offset}%, 0px , 0px)`);
    }
  }
  public setAutoSlider(flag: boolean) {
    this.blockAutoMainSlider = flag;
  }
  private mainSliderauto() {
    this.mainNext();
  }
  //main slider ends

  // core values slider begins
  public valuesPrev() {
    this.coreSliderNextDisabled = false;
    const sliderItemWidth = this.coreValuesSliderParent.nativeElement.children[0].offsetWidth;
    if (this._coreValuesSliderIndex > 0) {
      this._coreValuesSliderIndex -= 1;
      if (this._coreValuesSliderIndex <= 0) {
        this.coreSliderPrevDisabled = true;
      }
    }
    const offset = this._coreValuesSliderIndex * sliderItemWidth;
    this.slideValueItem(offset);
  }
  public valuesNext() {
    this.coreSliderPrevDisabled = false;
    const sliderItemCount = this.coreValuesSliderParent.nativeElement.children.length;
    const sliderItemWidth = this.coreValuesSliderParent.nativeElement.children[0].offsetWidth;
    if ((this._coreValuesSliderIndex + this._sliderItemsShowing) < sliderItemCount) {
      this._coreValuesSliderIndex += 1;
      if ((this._coreValuesSliderIndex + this._sliderItemsShowing) >= sliderItemCount) {
        this.coreSliderNextDisabled = true;
      }
    }
    const offset = this._coreValuesSliderIndex * sliderItemWidth;
    this.slideValueItem(offset);
  }
  private slideValueItem(offset) {
    for (let child of this.coreValuesSliderParent.nativeElement.children) {
      this.renderer2.setStyle(child, 'transform', `translate3d(${offset}px, 0px , 0px)`);
    }
  }
  private resetValueSlider() {
    if (this.coreValuesSliderParent !== undefined) {
      this.slideValueItem(0);
      this.coreSliderPrevDisabled = true;
      this.coreSliderNextDisabled = false;
      this._coreValuesSliderIndex = 0;
    }
  }
  // core values slider ends

  //teams slider begings
  public teamPrev() {
    this.teamSliderNextDisabled = false;
    const sliderItemWidth = this.teamSliderParent.nativeElement.children[0].offsetWidth;
    if (this._teamSliderIndex > 0) {
      this._teamSliderIndex -= 1;
      if (this._teamSliderIndex <= 0) {
        this.teamSliderPrevDisabled = true;
      }
    }
    const offset = this._teamSliderIndex * sliderItemWidth;
    this.slideTeamItem(offset);
  }
  public teamNext() {
    this.teamSliderPrevDisabled = false;
    const sliderItemCount = this.teamSliderParent.nativeElement.children.length;
    const sliderItemWidth = this.teamSliderParent.nativeElement.children[0].offsetWidth;
    if ((this._teamSliderIndex + this._sliderItemsShowing) < sliderItemCount) {
      this._teamSliderIndex += 1;
      if ((this._teamSliderIndex + this._sliderItemsShowing) >= sliderItemCount) {
        this.teamSliderNextDisabled = true;
      }
    }
    const offset = this._teamSliderIndex * sliderItemWidth;
    this.slideTeamItem(offset);
  }
  private slideTeamItem(offset) {
    for (let child of this.teamSliderParent.nativeElement.children) {
      this.renderer2.setStyle(child, 'transform', `translate3d(-${offset}px, 0px , 0px)`);
    }
  }
  private resetTeamSlider() {
    if (this.teamSliderParent !== undefined) {
      this.slideTeamItem(0);
      this.teamSliderPrevDisabled = true;
      this.teamSliderNextDisabled = false;
      this._teamSliderIndex = 0;
    }
  }
  //teams slider ends

  //partner slider begins
  private initializePartnerSliderList() {
    this.partnerSliderList.forEach((item) => {
      this.partnerSliderRotatingList.push(item);
    });
  }
  private rotatePartnerSlider() {
    const sliderLength = this.partnerSliderList.length - 1;
    if (this._partnerSliderIndex < sliderLength + 1) {
      this.partnerSliderItemFlag = false;
      setTimeout(() => {
        this._partnerSliderIndex += 1;
        for (let i = 0; i <= sliderLength; i += 1) {
          this.partnerSliderRotatingList[i] = this.partnerSliderList[i + this._partnerSliderIndex];
        }
        const removedIndex = (sliderLength - this._partnerSliderIndex) + 1;
        let startIndex = 0;
        for (let i = removedIndex; i <= sliderLength; i += 1) {
          this.partnerSliderRotatingList[i] = this.partnerSliderList[startIndex];
          startIndex += 1;
        }
        this.partnerSliderItemFlag = true;
      }, 400);

    } else {
      this._partnerSliderIndex = 0;
      this.rotatePartnerSlider();
    }
  }
  //partner slider ends

  //feedback from starts
  public sentFeedback() {
    this.formInvalid = true;
    if (this.feedbackForm.invalid) {
      this.feedbackSuccessFlag = false;
      this.formInvalid = false;
      return;
    }
    this.feedbackModel = this.feedbackForm.value;
    this.EmailContent = this.createFeedbackEmailTemplate(this.feedbackModel);
    let emailRequest = this.createEmailRequest(this.EmailContent, this._submitEmailId);
    this.mailService.sentEmail(emailRequest).subscribe((res: any) => {
      console.log("Response :", res);
    });
    this.EmailContent = this.createUserEmailTemplate();
    emailRequest = this.createEmailRequest(this.EmailContent, this.feedbackModel.email)
    this.mailService.sentEmail(emailRequest).subscribe((res: any) => { });
    this.feedbackForm.reset();
    this.feedbackSuccessFlag = true;
  }
  private createFeedbackEmailTemplate(feedbackFormValues: Contact) {
    let mailContent: string = `<html>
    <body>    
    <h1>Feedback</h1>
    <p>Type : ${feedbackFormValues.type} </p>
    <p>Branch : ${feedbackFormValues.branchVisited} </p>
    <p>Name : ${feedbackFormValues.name}</p>
    <p>Email : ${feedbackFormValues.email} </p>
    <p>Feedback : ${feedbackFormValues.feedback} </p> 
    </body>
    </html>`;
    return mailContent;
  }
  private createUserEmailTemplate() {
    let mailContent: string = `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "https://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
    <html xmlns="https://www.w3.org/1999/xhtml">
    <head>
    <title>Feedback mail</title>
    </head>
    <body>
    <table  width="600" border="0" align="center" cellspacing="0" cellpadding="0" style="font-family:Arial, Helvetica, sans-serif; font-size:12px; color:#525252; border:1px solid #dadada; background-color:#FFF;"><tr>
        <td align="center" style="padding:15px 0"><img src="${this._imagePath}/logo.png" /></td>
      </tr>
      
      <tr>
        <td align="center" style="padding:6px 0; font-weight:bold; font-size:15px; text-transform:uppercase; background:#f6f7f8; color:#000;border-top: 1px solid #f0f0f0;    border-bottom: 1px solid #f0f0f0;">&nbsp;</td>
      </tr>
      
      <tr>
        <td valign="top">
        
        
        
        <table  width="100%" border="0" cellspacing="0" cellpadding="0">
    
        
            
            
            <td  valign="middle"  style="padding:10px"><table  width="100%" border="0" cellspacing="0" cellpadding="0"  style="font-family:Arial, Helvetica, sans-serif; font-size:13px; color:#000;">
            <tr>
                    <td style="font-size:13px;font-family:Arial, Helvetica, sans-serif; color:#000; font-weight:normal; line-height:23px; padding:5px 0px 10px;">
                    Dear Valued Customer,<br />
    <span style="width:100%; float:left; color:#18ad0d;; font-size:18px; font-weight:bold">Thank you for choosing Al Jazira Exchange.</span>
                    </td>
                  </tr>
                  <tr>
                  <td style="font-size:13px;font-family:Arial, Helvetica, sans-serif; color:#000; font-weight:normal; line-height:23px; padding-top:15px;">
                  Your honest feedback/suggestion is important for us as it will help us to serve you better and enable us to improve our service standards. We appreciate the time and effort you have spent to share your feedback, which will be earnestly considered. We assure you that your concern will be addressed within 2 business days. Alternatively, you can also contact us at +971-2-5658866 or email at <a href="mailto:customercare@jaziraexchange.com">customercare@jaziraexchange.com</a>, for following up on your feedback/complaint.
                  <br /> <br />
                  Customer Care Department <br />
Al Jazira Exchange

</td>
                  </tr>
    
                </table></td>
    
            <tr>
              <td height="20">&nbsp;</td>
            </tr>
            <tr>
              <td align="center">&nbsp;</td>
            </tr><tr>
              <td valign="top" style="border-top:1px solid #dadada; border-bottom:1px solid #dadada; padding:1% 0"><table  width="100%" border="0" cellspacing="0" cellpadding="0" style="padding:15px 0; font-family:Arial, Helvetica, sans-serif; padding:1% 2%"><tr>
                    <td style="font-family:Arial, Helvetica, sans-serif; font-size:12px; color:#525252; line-height:20px"><span style="color:#ff6600">Note</span> -This is a system generated message. We request you not to reply to this message</td>
                  </tr>
                </table></td>
            </tr>
            <tr>
              <td align="center" valign="top">&nbsp;</td>
            </tr>
            <tr>
              <td align="center" valign="middle">
              <table  width="500" border="0" cellspacing="0" cellpadding="0" style="font-weight:bold;  font-family:Arial, Helvetica, sans-serif; font-size:12px; color:#525252;">
                <tr>
                  <td width="18"><img src="${this._imagePath}/phone-icon.png" width="16" height="17" alt="" /></td>
                  <td width="144" style="font-size:12px; color:#525252; font-family:Arial, Helvetica, sans-serif; padding-left:3px">+971-2-5658866</td>
                
                
                  <td width="18" style="padding-top:5px"><img src="${this._imagePath}/mail-icon.png" width="15" height="11" alt="" /></td>
                  <td width="144" style="padding-top:5px;font-size:12px; color:#525252; font-family:Arial, Helvetica, sans-serif; padding-left:3px">customercare@jaziraexchange.com</td>
                </tr>
              </table></td>
            </tr><tr>
              <td valign="top"><table  width="100%" border="0" cellspacing="0" cellpadding="0" style="font-family:Arial, Helvetica, sans-serif;">
                <tr>
                  <td   style="font-size:13px; font-family:Arial, Helvetica, sans-serif; color:#525252;  font-size:12px; font-family:Arial, Helvetica, sans-serif; text-align:center ;border-bottom:1px solid #dadada;">&nbsp;</td>
                </tr>
                <tr>
                    <td   style="font-size:13px; font-family:Arial, Helvetica, sans-serif; color:#525252;  font-size:12px; font-family:Arial, Helvetica, sans-serif; text-align:center; padding:2% 0"> Copyright &copy; ${this.copywrightDate} <a href="www.jaziraexchange.ae" style=" color:#002543;  font-size:12px; font-family:Arial, Helvetica, sans-serif">www.jaziraexchange.ae</a> .All Rights Reserved.</td>
                  </tr>
                </table></td>
            </tr>
          </table></td>
      </tr>
    </table>
    </body>
    </html>`;
    return mailContent
  }
  private createEmailRequest(mailContent: string, toAddress: string) {
    let request: MailRequest = {} as MailRequest;
    request.MailTo = toAddress;
    request.ClientID = "";
    request.TUI = "";
    request.MailContent = mailContent;
    request.isHtml = true;
    request.MailSub = "Al jazira Feedback";
    request.MsgType = "EML";
    return request;
  }
  //feedback from ends

  public showEnquiry(element) {
    element.scrollIntoView({ behavior: 'smooth'});
  }
  
  switchLanguage() {
    this.router.navigate(['']);
  }
}
