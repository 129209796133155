export class Contact {
    type: string;
    branchVisited: string;
    name: string;
    email: string;
    subject: string;
    feedback: string;
}

export class MailRequest {
    TUI: string;
    ClientID: string;
    MsgType: string;
    MailTo :string;
    MailSub:string
    MailContent:string;
    isHtml:Boolean;
}

export interface SignatureResponse{
    TUI:string;
    Token:string;
    Code:string;
    Msg:Array<string>;

}