import { Injectable } from '@angular/core';
import { MailRequest, SignatureResponse } from 'src/app/model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ReplaySubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class EmailServiceService {
  private token: string = null;
  public tocken: any;

  constructor(private http: HttpClient) {
    this.Authenticate();
  }

  sentEmail(request: MailRequest) {
    let headers = new HttpHeaders({ 'authorization': 'Bearer ' + this.token });
    return this.http.post('https://b2capi.akbartravels.com/Message/SendMessage', request, { headers: headers });
  }

  public Authenticate() {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const postObservable = this.http.post<SignatureResponse>('https://b2cutilsapi.akbartravels.com/Utils/Signature',
      {
        "TUI": this.tui(),
        "MerchantID": 801,
        "ApiKey": "fW34J&A9Xn"
      }, { headers });
    const subject = new ReplaySubject<SignatureResponse>(1);
    subject.subscribe((r: SignatureResponse) => {
      this.setAccessToken(r.Token);
    }, (err) => {
    });
    postObservable.subscribe(subject);
    return subject;
  }

  private setAccessToken(accessToken: string) {
    this.token = accessToken;
  }

  private tui() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

}
